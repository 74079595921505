import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.css'

import bernardo from '../../assets/images/bernado.png'
import leonice from '../../assets/images/leonice.png'
import ariana from '../../assets/images/ariana.png'
import jose_sobreiro from '../../assets/images/jose_sobreiro.png'

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <Slider {...settings}>
      <div className='carousel-item'>
        <img
          src={bernardo}
          alt='Bernardo'
        />
        <p className='frase'>"O observatório Maranhão Agrário é o mais novo território da luta pela terra no estado."</p>
        <p>Bernardo Mançano Fernandes</p>
        <p>Geógrafo - UNESP</p>
      </div>
      <div className='carousel-item'>
        <img
          src={leonice}
          alt='Leonice'
        />
        <p className='!leading-4'>"O Observatório Maranhão Agrário é um espaço democrático onde todos podem compartilhar e encontrar novos saberes."</p>
        <p>Maria Leonice Martins</p>
        <p>Professora de História - IEMA</p>
      </div>
      <div className='carousel-item'>
        <img
          src={jose_sobreiro}
          alt='José Sobreiro'
        />
         <p className='!leading-5'>"Com engajamento <i>sui generis</i>, o Observatório Maranhão Agrário se propõe a analisar os movimentos da realidade e propor reflexões e soluções inteligente para a realidade dos movimentos."</p>
        <p>José Sobreiro Filho</p>
        <p>Geógrafo - UNB</p>
      </div>
      <div className='carousel-item'>
        <img
          src={ariana}
          alt='Ariana'
        />
         <p className='!leading-4'>"O observatório traz memórias escritas e desenhadas, de lutas do passado e do presente."</p>
        <p>Ariana Silva</p>
        <p>Secretária Executiva da RAMA</p>
      </div>
      {/* Adicione mais itens conforme necessário */}
    </Slider>
  )
}

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        right: '-30px', // Ajuste a distância da borda direita
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        left: '-30px', // Ajuste a distância da borda esquerda
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

export default Carousel
